<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <v-row>
            <v-col cols="12" md="8" sm="12" class="mxmnheight" style="padding-top:0px;">
                <v-row style="background:#eee;border:1px solid #ccc;padding:10px">
                    <b-button class="btn-sm btn btn-yallow wp-150" style="width:150px !important;" v-for="(item,index) in categories" :key="index" :id="`cats_`+index" @click="changeColor(index), getCats(item)" >{{item.name}}</b-button>
                </v-row>
                <v-row class="mxmnheight-200 m-3">
                    <b-button class="btn-sm btn wp-150 btn-primary" 
                        v-for="(item,index) in subcats" @click="getCats(item)" :key="index">
                      {{item.name}}
                    </b-button>
                    <b-button class="btn-sm btn btn-gray wp-150" 
                        v-for="(item,index) in allitems" @click="addItem(item)" :key="index">
                      <span v-if="lang.langname == 'ar'">{{item.name}}</span>
                      <span v-if="lang.langname == 'en'">{{item.nameen}}</span>
                      <br>{{item.item_price}} {{lang.curency}}</b-button>

                </v-row>
                <div id="countbtns">
                    <b-button class="btn-sm btn btn-success" @click="addOne()" style="width:50px;margin-inline-start:5px;">+</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(1)" style="width:50px;margin-inline-start:5px;">1</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(5)" style="width:50px;margin-inline-start:5px;">5</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(10)" style="width:50px;margin-inline-start:5px;">10</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(15)" style="width:50px;margin-inline-start:5px;">15</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(20)" style="width:50px;margin-inline-start:5px;">20</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(25)" style="width:50px;margin-inline-start:5px;">25</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(30)" style="width:50px;margin-inline-start:5px;">30</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(50)" style="width:50px;margin-inline-start:5px;">50</b-button>
                    <b-button class="btn-sm btn btn-info" @click="addThis(100)" style="width:50px;margin-inline-start:5px;">100</b-button>
                    <b-button class="btn-sm btn btn-notfy" @click="minOne()" style="width:50px;margin-inline-start:5px;">-</b-button>
                    <b-button class="btn-sm btn btn-danger" @click="delSel()" style="width:50px;margin-inline-start:5px;">{{lang.delete}}</b-button>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="mxmnheight" style="background:#eee;border:1px solid #ccc;">
                <v-row>
                    <v-col cols="4">
                        <label>{{lang.mobile}}</label>
                        <b-form-input
                        :label="lang.mobile"
                        v-model="customer.mobile"
                        class="inborder"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                    </v-col>
                    <v-col cols="8">
                        <label>{{lang.full_name}}</label>
                        <b-form-input
                        :label="lang.full_name"
                        v-model="customer.full_name"
                        class="inborder"
                        >{{ customer.full_name }}</b-form-input>
                    </v-col>
                </v-row>
                <div class="invoicetable">
                    <v-simple-table id="billtable">
                        <thead>
                            <tr>
                                <th class="text-center">{{lang.description}}</th>
                                <th class="text-center" style="width:15%">{{lang.qtty}}</th>
                                <th class="text-center" style="width:15%">{{lang.total}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in itemrows" :key="index" :id="`item_`+index" @click="selectThie(item,index)">
                                <td class="itemrowstd" style="height:40px !important">{{item.name}}</td>
                                <td class="itemrowstd" style="height:40px !important">{{item.qty}}</td>
                                <td class="itemrowstd" style="height:40px !important">{{item.ftotal}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <div style="position:absolute;bottom:5px;left:0px;right:5px;">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th style="width:100px;">{{lang.total}}</th>
                                <td>{{intotal}}</td>
                                <th style="width:100px;">{{lang.total_after_discount}}</th>
                                <td>{{total_after_discount}}</td>
                            </tr>
                            <tr>
                                <th style="width:100px;">{{lang.vat}} 15%</th>
                                <td>{{invat}}</td>
                                <th style="width:100px;">{{lang.ftotal}}</th>
                                <td>{{inftotal}}</td>
                            </tr>
                        </thead>
                    </v-simple-table>
                    <b-button class="btn-sm btn btn-info" style="width:45%;margin-inline-start:5px;margin-bottom:5px;" @click="$refs.discountMe.checkDiscoutn()" v-b-toggle.pos_discount>{{lang.discount}}</b-button>
                    <b-button class="btn-sm btn btn-warning" style="width:45%;margin-inline-start:5px;margin-bottom:5px;" v-b-toggle.pos_notes>{{lang.invoice_notes}}</b-button>
               
                    <b-button class="btn-sm btn btn-success" style="width:45%;margin-inline-start:5px;" @click="updatePaidAmount()" v-b-toggle.pos_addinvoice>{{lang.add}}</b-button>
                    <b-button class="btn-sm btn btn-danger" @click="cancelOrder()" style="width:45%;margin-inline-start:5px;">{{lang.cancel}}</b-button>
                
                </div>
            </v-col>
        </v-row>
        <posDiscount ref="discountMe" />
        <pos-notes />
        <pos-addinvoice ref="addInvo" />
        <vue-snotify></vue-snotify>
    </div>
    <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import posDiscount from '@/components/pos_discount.vue';
import PosNotes from '@/components/pos-notes.vue';
import PosAddinvoice from '@/components/pos-addinvoice.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';

export default{
  components: { posDiscount, PosNotes, PosAddinvoice, HeaderPortrate, Footer },
    name: 'POS',
    data() {
        return {
            selectedIndex: -1,
            selectedItem: {},
            categories: [],
            subcats:[],
            itemrows: [],
            allitems: [],
            customer:{
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            },
            company:{
                fullname: '',
                vatid: '',
            },
            discount: 0,
            notes: '',
            paytype: 1,
            invtype: 1,
            paytypes: [],
            paidamount: 0,
            cash: '',
            span: ''
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        invtypes: function(){
            return [
                {
                    text: this.lang.none_paid_invoice,
                    value: 2,
                },
                {
                    text: this.lang.paid_invoice,
                    value: 1,
                },
            ]
        },
        curency: function(){
            return this.lang.curency;
        },
        total_after_discount: function(){
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.intotal) - parseFloat(this.discount);
            return this.$RoundNum(t);
        },
        intotal: function(){
            let t = 0;
            for(let i=0;i<this.itemrows.length;i++){
                t = parseFloat(t) + parseFloat(this.itemrows[i].total);
            }
            return this.$RoundNum(t);
        },
        invat: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].vat);
            // }
            
            t = parseFloat(this.total_after_discount) * parseFloat(0.15)
            // t = parseFloat(this.total_after_discount) * parseFloat(0)
            return this.$RoundNum(t);
        },
        inftotal: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].ftotal);
            // }
            // t = parseFloat(t) * parseFloat(this.discountper)
            t = parseFloat(this.total_after_discount) + this.invat;
            return Math.round(this.$RoundNum(t));
        },
        discountper: function() {
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.discount) / parseFloat(this.intotal);
            
            return this.$RoundNum(t);
        }
    },
    methods: {
        changeColor(index){
            for(let i=0;i<this.categories.length;i++){
                if(index == i){
                    document.getElementById('cats_'+i).classList.add("btn-balck");
                    document.getElementById('cats_'+i).classList.remove("btn-yallow");
                }else{
                    document.getElementById('cats_'+i).classList.add("btn-yallow");
                    document.getElementById('cats_'+i).classList.remove("btn-balck");
                }
            }
        },
        getPaymentMethod(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            let x = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    for(let i = 0;i<res.results.data.length;i++){
                        if(i == 0){
                            this.paytype = res.results.data[i].id;
                        }
                        x.push({
                            text: this.$cookies.get(this.$store.state.LangCooki) == "ar" ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                            value: res.results.data[i].id
                        })
                    }
                    // x.push({
                    //   text: 'دفع متعدد',
                    //   value: -1
                    // })
                    this.paytypes = x;
                    // this.paytypes.push(
                    //     {
                    //         text: this.lang.multi_payment,
                    //         value: -1
                    //     }
                    // )
                }
            )
        },
        cancelOrder(){
            this.subcats = []
            this.itemrows =  []
            this.allitems =  []
            this.customer = {
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            }
            this.company = {
                fullname: '',
                vatid: '',
            }
            this.discount =  0
            this.notes = '';
            this.paytype = 1;
            this.invtype = 1;
            this.paidamount = 0;
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                    }
                }
            )
        },
        updatePaidAmount(){
            this.paidamount = this.inftotal;
            this.$refs.addInvo.cash = this.paidamount;
        },
        addItem(item){
            let check = false;
            for(let i=0;i<this.itemrows.length;i++){
                if(this.itemrows[i].id == item.id){
                    this.itemrows[i].qty = parseFloat(this.itemrows[i].qty) + parseFloat(1);
                    this.itemrows[i].ftotal = this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].item_price));
                    this.itemrows[i].total = this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].sprice));
                    this.itemrows[i].vat = 0; this.$RoundNum(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].svat));
                    check = true;
                }
            }
            if(!check){
                this.itemrows.push(item);
                this.paidamount = this.inftotal;
            }
        },
        selectThie(item,index){
           if(this.selectedIndex != -1){
               if(this.selectedIndex != index){
                   document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                   this.selectedIndex = index;
                    this.selectedItem = item;
                    document.getElementById('item_'+index).style.background = '#ccc';
                    return false;
               }
               this.selectedIndex = -1;
               this.selectedItem = {};
               document.getElementById('item_'+index).style.background = '#FFF';
           }else{
                this.selectedIndex = index;
                this.selectedItem = item;
                document.getElementById('item_'+index).style.background = '#ccc';
               
           }
        },
        delSel(){
            if(this.selectedIndex != -1){
                this.itemrows.splice(this.selectedIndex,1);
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addThis(n){
            if(this.selectedIndex != -1){
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addOne(){
            if(this.selectedIndex != -1){
                let n = +this.itemrows[this.selectedIndex].qty + 1;
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));                
            }
        },
        minOne(){
            if(this.selectedIndex != -1 && this.itemrows[this.selectedIndex].qty > 1){
                let n = +this.itemrows[this.selectedIndex].qty - 1;
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice));
                this.itemrows[this.selectedIndex].vat = this.$RoundNum(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat));
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNum(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat));
            }
        },
        getCats(item){
            if(item.type != 0 && item.id != 0){
                this.getProducts(item.id);
                return false;
            }
            const post = new FormData();
            post.append('type', 'getPCats');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            post.append('data[type]',item.type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                if(item.id == 0){
                    this.categories = res.results.data;
                }else{
                    this.allitems = [];
                    this.subcats = res.results.data;
                }
            })
        },
        getProducts(catid){
            this.subcats = [];
            const post = new FormData();
            post.append('type', 'getCatProducts');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',catid);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.allitems = res.results.data;
            })
        },
        addInvoice(){
            if(this.itemrows.length == 0){
                this.$snotify.error(this.lang.you_can_not_add_empty_invoice, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }

            const post = new FormData();
            post.append("type" , "addPOSInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));

            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.itemrows.length;i++){
                for(const [key, value] of Object.entries(this.itemrows[i])){
                    post.append('data[rows]['+i+']['+key+']]',value);
                }
            }
            
            post.append("data[discount]",this.discount);
            post.append("data[notes]",this.notes);
            post.append("data[paytype]",this.paytype);
            post.append("data[invtype]",this.invtype);
            post.append("data[paidamount]",this.paidamount);
            post.append("data[intotal]",this.intotal);
            post.append("data[invat]",this.invat);
            post.append("data[inftotal]",this.inftotal);
            post.append("data[total_after_discount]",this.total_after_discount);
            post.append("data[cash]",this.$refs.addInvo.cash);
            post.append("data[span]",this.$refs.addInvo.span);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,  post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.printPDF(res.results.data);
                this.cancelOrder();
            })
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print-80m.php?invid='+id;
          // await axios.get(
          //     '/api/print-80m.php?invid='+id
          // )
        },
    },
    created() {
        this.getCats({id:0,type:0});
        this.getPaymentMethod();
    },
}
</script>

<style>
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 150px);
    min-height:calc(100vh - 150px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:75px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#ccc;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    background:#222;
    color:#fff;
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#6c757d;
    color: #fff;
    border-radius: 7px;
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-balck,.btn-balck:active,.btn-balck:hover,.btn-balck:visited{
    background: #000 !important;
    color:#fff !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
</style>